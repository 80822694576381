export default {
  namespaced: true,
  state: {
    user: {
      id: null,
      email: 'Not Logged In',
      name: '',
      isAdmin: false,
      isAuthenticated: false,
    },
    msalConfig: {
      auth: {
        clientId: process.env.VUE_APP_AUTH_CLIENTID,
        authority: process.env.VUE_APP_AUTH_AUTHORITY,
        redirectUri: process.env.VUE_APP_AUTH_REDIRECT_URI
      },
      cache: {
        cacheLocation: 'localStorage',
      },
    },
    accessToken: '',
    dOpToken: ''
  },
  getters: {
    user(state) {
      return state.user
    },
    msalConfig(state) {
      return state.msalConfig
    },
    userDetail: (state) => (selector) => {
      return state.user[selector]
    },
    accessToken(state) {
      return state.accessToken
    },
    dOpToken(state) {
      return state.dOpToken
    }
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    setAccessToken(state, payload) {
      state.accessToken = payload
    },
    setDOpToken(state, payload) {
      state.dOpToken = payload
    }
  },
  actions: {
    setUser(context, data) {
      const userData = {
        id: data.id,
        email: data.email,
        name: data.name,
        isAdmin: data.isAdmin,
        isAuthenticated: data.isAuthenticated
      }
      context.commit('setUser', userData)
    },
    clearUserData(context) {
      const userData = {
        id: '',
        email: 'Not Logged In',
        name: '',
        isAdmin: false,
        isAuthenticated: false
      }
      context.commit('setUser', userData)
      context.commit('setAccessToken', '')
      context.commit('setDOpToken', '')
    },
    setAccessToken(context, data) {
      context.commit('setAccessToken', data)
    },
    setDOpToken(context, data) {
      context.commit('setDOpToken', data)
    }
  },
}