
import reqMutations from './mutations'
import reqGetters from './getters'
import reqActions from './actions'

export default {
  namespaced: true,
  state: {
    request: {
      id: null,
      requestTypeId: null,
      title: '',
      summary: '',
      detail: [],
      score: {
        "final": null,
        "value": null,
        "criticality": null,
        "risk": null,
        "effort": null
      },
      devopsID: null
    },
    requests: [],
    adminRequests: {
      pendingRequests: [],
      approvedRequests: [],
      completedRequests: []
    },
    preFormQuestions: {
      sectionTitle: 'What type of job are you requesting?',
      questions: [
        {
          question: 'Add a title',
          type: 'FormDropDown',
          args: {
            items: [],
            label: 'Select job type',
            placeholder: "Select job",
            chips: false,
            multi: false,
            validation: {rules: ""}
          },
          answer: null
        }
      ]
    },
    requestTypes: [],
    mapWITypeToRequestTypeId: [
      {id: 1, wIType: 'Bug'},
      {id: 6, wIType: 'Change Request'},
      {id: 7, wIType: 'User Story'},
      {id: 8, wIType: 'Feature'},
      {id: 9, wIType: 'Epic'}
    ],
    validationRefs: [],

  },
  getters: reqGetters,
  mutations: reqMutations,
  actions: reqActions
}