import Vue from 'vue'
import router from '../router'

// Import the plugin here
import { Auth0Plugin } from '../auth'

// Import environment variables
const domain = 'dev-fikpsi0f.eu.auth0.com'
const clientId = 'uhhIHHElSm66Y0x7pBDmjiR0yp3liufv'
const audience = 'https://api.frog.net'

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})
