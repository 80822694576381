<template>
	<div>
		<SingleViewDialog
			:loading="loading"
			:showDevOps="showDevOps"
			:devOpsId="id"
			:calcOpen="isCalc"
			:comments="devOpsComments"
			@hideDevOpsDialog="handleCloseDialog"
			@updateRequests="handleUpdateRequests" />

		<v-dialog
			v-model="hideDialog"
			persistent
			max-width="290">
			<v-card>
				<v-card-title class="text-h5"> Hide this request </v-card-title>
				<v-card-text> Are you sure you want to hide request with id: {{ id }} from view? </v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="grey"
						@click.stop="hideDialog = false">
						Cancel
					</v-btn>
					<v-btn
						color="red"
						@click.stop="handleHideClick(id)">
						Hide
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog
			v-model="archiveDialog"
			persistent
			max-width="290">
			<v-card>
				<v-card-title class="text-h5"> Archive this request </v-card-title>
				<v-card-text> Are you ready to archive request with id: {{ id }}? </v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="grey"
						@click.stop="archiveDialog = false">
						Cancel
					</v-btn>
					<v-btn
						color="red"
						@click.stop="handleArchiveClick(id)">
						Archive
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog
			v-model="completeDialog"
			persistent
			max-width="290">
			<v-card>
				<v-card-title class="text-h5">Complete Job</v-card-title>
				<v-card-text>Would you like to mark job {{ id }} as completed?</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="grey"
						@click.stop="completeDialog = false">
						Cancel
					</v-btn>
					<v-btn
						color="blue"
						@click.stop="handleCompleteClick(id)">
						Complete Job
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<div class="d-flex justify-center align-center">
			<v-btn
				class="my-4 px-5"
				v-if="!hideCreate"
				rounded
				color="#5054f1"
				:to="'/edit-request/new'">
				Create new request
			</v-btn>
		</div>

		<div class="d-flex justify-center align-center">
			<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				label="Search"
				single-line
				hint="Search by Title or ID"
				class="search-bar"></v-text-field>
		</div>

		<v-data-table
			singleExpand
			:headers="headers"
			:items="requests"
			:item-key="'System.Id'"
			:search="search"
			:sort-by="['System.Id']"
			:sort-desc="[true]"
			@click:row="handleRowClick"
			class="transparent">
			<template v-slot:[`item.actions`]="{ item }">
				<div class="d-inline-flex">
					<v-icon
						class="mr-2"
						v-if="isAdmin"
						@click.stop="handleCalcClick(item['System.Id'], true)">
						mdi-calculator</v-icon
					>
					<v-icon
						class="mr-2"
						@click.stop="openArchiveDialog(item['System.Id'])"
						v-if="isAdmin">
						mdi-archive
					</v-icon>
					<v-icon
						class="mr-2"
						@click.stop="openHideDialog(item['System.Id'])"
						v-if="!isDel">
						mdi-eye-off
					</v-icon>
				</div>
			</template>
			<template v-slot:[`item.System.CreatedDate`]="{ item }">
				{{ normalDate(item['System.CreatedDate']) }}
			</template>
			<template v-slot:[`item.System.BoardColumn`]="{ item }">
				<v-chip :color="getColor(item['System.BoardColumn'])">
					{{ item['System.BoardColumn'] }}
				</v-chip>
			</template>
			<template v-slot:[`item.System.CommentCount`]="{ item }">
				<div class="d-inline-flex">
					<v-icon class="mr-2"> mdi-comment</v-icon> {{ item['System.CommentCount'] }}
				</div>
			</template>
			<template v-slot:[`item.System.Title`]="{ item }">
				<div
					class="text-truncate"
					style="max-width: 300px">
					{{ item['System.Title'] }}
				</div>
			</template>
		</v-data-table>
	</div>
</template>
<script>
import Loading from '../ui/Loading.vue'
import RequestItem from './RequestItem.vue'
import SingleViewDialog from './SingleViewDialog.vue'
import dayjs from 'dayjs'

export default {
	name: 'Devops-Requests-List',
	props: ['loading', 'requests', 'isAdmin', 'isDel', 'hideCreate'],
	emits: ['archiveRequestClick, completeRequestClick', 'updateRequests'],
	components: { RequestItem, Loading, SingleViewDialog },
	data() {
		return {
			isCalc: false,
			search: '',
			id: null,
			// request: [],
			requestId: null,
			hideDialog: false,
			archiveDialog: false,
			createDialog: false,
			completeDialog: false,
			showDevOps: false,
			defaultHeaders: [
				{ text: 'Card ID', align: 'start', value: 'System.Id', sortable: true },
				{ text: 'Title', align: 'start', value: 'System.Title' },
				{ text: 'Created', align: 'start', sortable: true, value: 'System.CreatedDate' },
				{ text: 'Request Status', align: 'center', value: 'System.BoardColumn' },
				{ text: 'Comments', align: 'center', value: 'System.CommentCount', sortable: false },
			],
			adminHeaders: [{ text: 'Actions', align: 'center', value: 'actions', sortable: false }],
		}
	},
	computed: {
		devOpsComments() {
			return this.$store.getters['devops/getDevOpsComments']
		},
		headers() {
			if (!this.isAdmin) {
				return this.defaultHeaders
			} else if (this.isAdmin) {
				return [...this.defaultHeaders, ...this.adminHeaders]
			}
		},
	},
	methods: {
		getColor(requestState) {
			const options = [
				{ columnName: 'Submitted', value: 'yellow darken-2' },
				{ columnName: 'Acknowledged', value: 'orange darken-2' },
				{ columnName: 'Processed', value: 'orange darken-4' },
				{ columnName: 'Rejected', value: 'red' },
				{ columnName: 'Selected', value: 'cyan' },
				{ columnName: 'Blocked', value: 'purple' },
				{ columnName: 'In Progress', value: '#00008b' },
				{ columnName: 'Review', value: 'blue darken-4' },
				{ columnName: 'Release', value: 'blue darken-4' },
				{ columnName: 'Testing', value: 'blue' },
				{ columnName: 'Approval', value: 'cyan darken-2' },
				{ columnName: 'Completed', value: 'green' },
			]
			const [colour] = options.filter((el) => el.columnName === requestState)
			return colour ? colour.value : 'grey'
		},
		initialize() {
			this.requests
		},
		closeDelete() {
			this.dialogDelete = false
		},
		openHideDialog(reqId) {
			this.id = reqId
			this.hideDialog = true
		},
		openArchiveDialog(reqId) {
			this.id = reqId
			this.archiveDialog = true
		},
		openCompleteDialog(reqId) {
			this.id = reqId
			this.completeDialog = true
		},
		openItemDialog(reqId, calcOpen) {
			this.requestId = reqId
			this.id = reqId
			this.showDevOps = true
			this.isCalc = calcOpen
			this.$store.dispatch('devops/getDevOpsComments', { id: reqId })
		},
		handleCloseDialog() {
			this.showDevOps = false
			this.isCalc = false
		},
		handleCalcClick(reqId) {
			this.openItemDialog(reqId, true)
		},
		handleRowClick(item) {
			const id = item['System.Id']
			this.openItemDialog(id, false)
		},
		async handleHideClick(reqId) {
			this.hideDialog = false
			const [frogRequest] = this.$store.getters['requests/getRequestByDevopsId'](reqId)
			await this.$store.dispatch('requests/hideOrArchiveRequest', { requestId: frogRequest.id })
			this.$emit('updateRequests')
			this.id = ''
		},
		async handleArchiveClick(reqId) {
			this.archiveDialog = false
			const [frogRequest] = this.$store.getters['requests/getRequestByDevopsId'](reqId)
			await this.$store.dispatch('requests/hideOrArchiveRequest', { requestId: frogRequest.id })
			this.$emit('updateRequests')
			this.id = ''
		},
		handleUpdateRequests() {
			this.$emit('updateRequests')
		},
		normalDate(date) {
			if (!date) {
				return null
			}
			return dayjs(date).format('DD-MMM-YY')
		},
	},
	created() {
		this.initialize()
	},
}
</script>

<style lang="scss">
.request-item-container {
	width: 100%;
}
.theme--light.v-card {
	background: $ashiabody !important;
}

.v-tabs-slider {
	background-color: $ashiacyan;
}
// Width can be changed to 50% if moved to filter component
.search-bar {
	width: 100%;
}
th,
td {
	font-size: 16px !important;
}
.theme--dark.v-data-table
	> .v-data-table__wrapper
	> table
	> tbody
	> tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
	background: rgba(100, 100, 255, 0.1);
}
</style>
