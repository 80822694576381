<template>
	<v-row justify="center">
		<v-dialog
			v-model="showDevOps"
			persistent>
			<v-card>
				<v-card-title>
					ID {{ devOpsId }} | {{ requestData['System.Title'] }}
					<v-spacer></v-spacer>
					<v-btn
						icon
						@click="hideDialog()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text class="key-info-section">
					<v-row
						class="mt-2"
						align="center">
						<v-col>
							<h4>Created Date</h4>
							{{ normalDate(requestData['System.CreatedDate']) }}
						</v-col>
						<v-col>
							<h4>Work Item Type</h4>
							{{ requestData['System.WorkItemType'] }}
						</v-col>
						<v-col>
							<h4 v-if="requestData['Custom.Project']">Project</h4>
							{{ requestData['Custom.Project'] }}
						</v-col>
						<v-col>
							<h4>Created By</h4>
							<div v-if="requestData['System.CreatedBy']">
								<v-avatar size="24">
									<img
										alt="user-avatar"
										:src="requestData['System.CreatedBy'].imageUrl" />
								</v-avatar>
								{{ requestData['System.CreatedBy'].displayName }}
							</div>
						</v-col>
						<v-col>
							<h4>Assigned To</h4>
							<div v-if="requestData['System.AssignedTo']">
								<v-avatar
									size="24"
									class="mr-1">
									<img
										alt="user-avatar"
										:src="requestData['System.AssignedTo'].imageUrl" />
								</v-avatar>
								{{ requestData['System.AssignedTo'].displayName }}
							</div>
							<div v-else>Unassigned</div>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-text>
					<v-container>
						<v-row>
							<!-- column 1 -->
							<v-col
								cols="12"
								sm="5">
								<div
									class="mb-4"
									v-if="requestData['System.Description']">
									<div class="card-title-container d-flex align-center">
										<h4>Description</h4>
									</div>
									<v-divider class="mb-2"></v-divider>
									<div v-html="requestData['System.Description']" class="text-container">
									</div>
								</div>
								<div
									class="mb-4"
									v-if="requestData['Microsoft.VSTS.TCM.ReproSteps']">
									<div class="card-title-container d-flex align-center">
										<h4>Bug Reproduce Steps</h4>
									</div>
									<v-divider class="mb-2"></v-divider>
									<div v-html="requestData['Microsoft.VSTS.TCM.ReproSteps']" class="text-container">
									</div>
								</div>

								<div class="mb-4">
									<div class="card-title-container d-flex align-center">
										<h4>Additional Info</h4>
									</div>
									<v-divider class="mb-2"></v-divider>
									<div
										class="mb-4"
										v-if="requestData['Custom.UserActions']">
										<h5 class="card-subtitle">What Actions Would This Feature Allow Users</h5>
										<div v-html="requestData['Custom.UserActions']" class="text-container">
										</div>
									</div>
									<div
										class="mb-4"
										v-if="requestData['Custom.ActionValues']">
										<h5 class="card-subtitle">What Value Will This Bring</h5>
										<div v-html="requestData['Custom.ActionValues']" class="text-container">
										</div>
									</div>
									<div
										class="mb-4"
										v-if="requestData['Custom.ActionExamples']">
										<h5 class="card-subtitle">Examples Of This Functionality</h5>
										<div v-html="requestData['Custom.ActionExamples']" class="text-container">
										</div>
									</div>
									<div
										class="mb-4"
										v-if="requestData['Custom.ChangeType']">
										<h5 class="card-subtitle">Change Type</h5>
										<div v-html="requestData['Custom.ChangeType']" class="text-container">
										</div>
									</div>
									<div
										class="mb-4"
										v-if="requestData['Custom.ReasonforChange']">
										<h5 class="card-subtitle">Reason For Change</h5>
										<div v-html="requestData['Custom.ReasonforChange']" class="text-container">
										</div>
									</div>
									<div
										class="mb-4"
										v-if="requestData['Microsoft.VSTS.Common.AcceptanceCriteria']">
										<h5 class="card-subtitle">Expected Behavior</h5>
										<div v-html="requestData['Microsoft.VSTS.Common.AcceptanceCriteria']" class="text-container">
										</div>
									</div>
									<div
										class="mb-4"
										v-if="requestData['Custom.Linktobug']">
										<h5 class="card-subtitle">Link to Bug</h5>
										<div v-html="requestData['Custom.Linktobug']" class="text-container">
										</div>
									</div>
									<div
										class="mb-4"
										v-if="requestData['Custom.OperatingSystems']">
										<h5 class="card-subtitle">Operating Systems</h5>
										<div v-html="requestData['Custom.OperatingSystems']" class="text-container"></div>
									</div>
									<div
										class="mb-4"
										v-if="requestData['Custom.Browser']">
										<h5 class="card-subtitle">Browsers</h5>
										<div v-html="requestData['Custom.Browser']" class="text-container"></div>
									</div>
									<div
										class="mb-4"
										v-if="requestData['Custom.FeatureList']">
										<h5 class="card-subtitle">Key Features</h5>
										<div v-html="requestData['Custom.FeatureList']" class="text-container">
										</div>
									</div>
									<div
										class="mb-4"
										v-if="requestData['Custom.MarketAnalysis']">
										<h5 class="card-subtitle">Who Wants This?</h5>
										<div v-html="requestData['Custom.MarketAnalysis']" class="text-container">
										</div>
									</div>
									<div
										class="mb-4"
										v-if="requestData['Custom.Competitors']">
										<h5 class="card-subtitle">Who Is Doing This Already?</h5>
										<div v-html="requestData['Custom.Competitors']" class="text-container">
										</div>
									</div>
								</div>
								<div
									class="mb-4"
									v-if="requestData['Custom.UploadedFiles']">
									<div class="card-title-container d-flex align-center">
										<h4>Uploaded Files</h4>
									</div>
									<div>
										<v-divider class="mb-2"></v-divider>
										<div v-html="requestData['Custom.UploadedFiles']" class="text-container"></div>
									</div>
								</div>

								<!-- <div
									class="mb-4"
									v-if="requestData['Custom.Screenshots']">
									<div class="card-title-container d-flex align-center">
										<h4>Screenshots</h4>
									</div>
									<div>
										<v-divider class="mb-2"></v-divider>
										{{ requestData['Custom.Screenshots'] }}
									</div>
								</div>

								<div
									class="mb-4"
									v-if="requestData['Custom.SupportingFileLinks']">
									<div class="card-title-container d-flex align-center">
										<h4>Supporting Files</h4>
									</div>
									<div>
										<v-divider class="mb-2"></v-divider>
										{{ requestData['Custom.SupportingFileLinks'] }}
									</div>
								</div> -->
							</v-col>
							<!-- Column 2 -->
							<v-col
								cols="12"
								sm="2">
								<v-row>
									<v-col>
										<div class="card-title-container d-flex align-center">
											<h4>Planning</h4>
										</div>
										<v-divider class="mb-2"></v-divider>
										<div
											class="mb-2"
											v-if="requestData['Microsoft.VSTS.Scheduling.TargetDate']">
											<h5 class="card-subtitle">Expected Date</h5>
											{{ normalDate(requestData['Microsoft.VSTS.Scheduling.TargetDate']) }}
										</div>
										<div
											class="mb-2"
											v-if="requestData['Microsoft.VSTS.Common.Severity']">
											<h5 class="card-subtitle">Priority</h5>
											{{ requestData['Microsoft.VSTS.Common.Severity'] }}
										</div>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<div class="card-title-container d-flex align-center">
											<h4>Scores</h4>
										</div>
										<v-divider class="mb-2"></v-divider>
										<div class="mb-2">
											<h5 class="card-subtitle">WSJF</h5>
											{{ requestData['Custom.WSJF'] }}
										</div>
										<div class="mb-2">
											<h5 class="card-subtitle">Business Value</h5>
											{{ requestData['Microsoft.VSTS.Common.BusinessValue'] }}
										</div>
										<div class="mb-2">
											<h5 class="card-subtitle">Time Criticality</h5>
											{{ requestData['Microsoft.VSTS.Common.TimeCriticality'] }}
										</div>
										<div class="mb-2">
											<h5 class="card-subtitle">Risk or Opportunity</h5>
											{{ requestData['Custom.RiskorOpportunity'] }}
										</div>
										<div class="mb-2">
											<h5 class="card-subtitle">Scheduling Effort</h5>
											{{ requestData['Microsoft.VSTS.Scheduling.Effort'] }}
										</div>
									</v-col>
								</v-row>
							</v-col>
							<!-- column 3 -->
							<v-col
								cols="12"
								sm="5">
								<div class="d-flex align-center">
									<h4 v-if="!isCalc">Discussion</h4>
									<h4 v-if="isCalc">Calculator</h4>
									<v-spacer></v-spacer>
									<v-btn
										icon
										@click="showComments"
										v-if="$msal.user.isAdmin">
										<v-icon :style="{ color: !isCalc ? '#2196F3' : 'white' }">mdi-comment</v-icon>
									</v-btn>
									<v-btn
										icon
										@click="showCalc"
										v-if="$msal.user.isAdmin">
										<v-icon :style="{ color: isCalc ? '#2196F3' : 'white' }">mdi-calculator</v-icon>
									</v-btn>
								</div>
								<v-divider></v-divider>
								<DiscussionSection
									:id="devOpsId"
									:messages="comments"
									@updateRequests="handleUpdateComments"
									v-if="!isCalc" />
								<Calculator
									v-if="isCalc && $msal.user.isAdmin"
									:requestStatus="requestData['System.BoardColumn']"
									@addScores="handleAddScores"
									@acknowledgeRequest="handleAcknowledgeRequest" />
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import { set } from 'vue'
import Calculator from '../tools/Calculator.vue'
import DiscussionSection from './molecules/DiscussionSection.vue'
import dayjs from 'dayjs'

export default {
	components: { DiscussionSection, Calculator },
	name: 'SingleViewDialog',
	props: ['loading', 'showDevOps', 'devOpsId', 'comments', 'calcOpen'],
	emits: ['hideDevOpsDialog', 'updateRequests'],
	data() {
		return {
			isCalc: false,
			messages: [this.comments],
		}
	},
	computed: {
		requestData() {
			if (!this.loading && this.devOpsId) {
				return this.$store.getters['devops/getDevOpsRequestById'](this.devOpsId)[0]
			} else return []
		},
	},
	watch: {
		showDevOps(value) {
			if (value) {
				this.isCalc = this.calcOpen
			}
		},
	},
	methods: {
		hideDialog() {
			this.$emit('hideDevOpsDialog')
		},
		showCalc() {
			this.isCalc = true
		},
		showComments() {
			this.isCalc = false
		},
		calcColor() {
			return '#51f0f1'
		},
		handleUpdateComments() {
			this.$emit('updateRequests')
		},
		async handleAddScores(addScores) {
			const updateBody = [
				{
					op: 'add',
					path: '/fields/Custom.WSJF',
					value: addScores.wsjf,
				},
				{
					op: 'add',
					path: '/fields/Custom.RiskorOpportunity',
					value: addScores.risk,
				},
				{
					op: 'add',
					path: '/fields/Microsoft.VSTS.Common.TimeCriticality',
					value: addScores.criticality,
				},
				{
					op: 'add',
					path: '/fields/Microsoft.VSTS.Common.BusinessValue',
					value: addScores.value,
				},
				{
					op: 'add',
					path: '/fields/Microsoft.VSTS.Scheduling.Effort',
					value: addScores.effort,
				},
			]
			// allows scores to be updated if request is already processed
			if (
				this.requestData['System.BoardColumn'] === 'Submitted' ||
				this.requestData['System.BoardColumn'] === 'Acknowledged'
			) {
				updateBody.push({
					op: 'add',
					path: this.getKanbanByWIT(this.requestData['System.WorkItemType']),
					value: 'Processed',
				})
			}
			try {
				await this.$store.dispatch('devops/updateDevopsCard', {
					id: this.devOpsId,
					body: updateBody,
				})
				this.$emit('updateRequests')
			} catch (err) {
				console.error('handleAddScores: ', err)
			}
		},
		async handleAcknowledgeRequest() {
			// devops UPDATE column and use ID
			if (this.requestData['System.BoardColumn'] === 'Submitted') {
				try {
					await this.$store.dispatch('devops/updateDevopsCard', {
						id: this.devOpsId,
						body: [
							{
								op: 'add',
								path: this.getKanbanByWIT(this.requestData['System.WorkItemType']),
								value: 'Acknowledged',
							},
						],
					})
					this.$emit('updateRequests')
				} catch (err) {
					console.error('handleAcknowledgeRequest: ', err)
				}
			}
		},
		getKanbanByWIT(wIType) {
			if (wIType === 'Bug' || wIType === 'User Story' || wIType === 'Change Request') {
				return '/fields/WEF_E6D54A47951F4BE385F24A6E163364CA_Kanban.Column'
			} else if (wIType === 'Feature') {
				return '/fields/WEF_7610603564764BB986A1C9F8E21C6295_Kanban.Column'
			} else if (wIType === 'Epic') {
				return '/fields/WEF_AC6720C8FEE148048F993D3814DFAAAE_Kanban.Column'
			}
		},
		normalDate(date) {
			if (!date) {
				return null
			}
			return dayjs(date).format('DD-MMM-YY')
		},
	},
}
</script>
<style scoped>
.theme--dark.v-card > .v-card__text {
	color: rgba(255, 255, 255, 1);
}
.card-subtitle {
	color: rgba(255, 255, 255, 0.7);
}
.card-title-container {
	height: 36px;
}
.key-info-section {
	background: rgba(18, 30, 66, 0.3);
}
.text-container {
	max-height: 112px;
	overflow: scroll;
	overflow-x: hidden;
	padding-right: 4px;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px rgba(18, 30, 66, 0.3);
	border-radius: 10px;
	margin-left: 5px;
}

::-webkit-scrollbar-thumb {
	background: rgba(18, 30, 66, 0.3);
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background: rgba(18, 30, 66, 0.4);
}
</style>
