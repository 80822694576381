<template>
	<div>
		<v-app-bar
			app
			class="nav-bar"
			height="80px">
			<div>
				<router-link to="/">
					<v-img
						class="d-flex justify-start"
						contain
						height="30px"
						width="180px"
						src="@/assets/Frog-Systems-logo.svg">
					</v-img>
				</router-link>
				
			</div>

			<v-tabs
				class="d-none d-lg-flex"
				v-model="tab"
				v-if="!drawer">
				<v-tab to="/">Home</v-tab>
				<v-tab to="/select-request">Saved Requests</v-tab>
				<v-tab
					v-if="$msal.user.isAdmin"
					to="/requests"
					>Process Requests</v-tab
				>
				<v-tab
					v-if="$msal.user.isSys"
					to="/test"
					>Data Test Rig</v-tab
				>
				<!-- <v-tab to="/calculator">Calculator</v-tab> -->
			</v-tabs>

			<v-spacer></v-spacer>
			<div class="d-flex align-center">
				<v-btn
					class="d-none d-lg-flex"
					color="#2196f3"
					rounded
					v-if="!this.$msal.user.isAuthenticated"
					@click="login">
					<v-icon
						dense
						color="#ffffff"
						class="mr-2">
						mdi-login-variant
					</v-icon>
					Login
				</v-btn>
				<v-btn
					class="d-none d-lg-flex"
					color="#2196f3"
					rounded
					v-if="this.$msal.user.isAuthenticated"
					@click="logout">
					<v-icon
						dense
						color="#ffffff"
						class="mr-2"
						>mdi-logout-variant</v-icon
					>
					{{ this.$msal.user.email }}</v-btn
				>
			</div>
			<v-app-bar-nav-icon
				@click="drawer = true"
				class="d-flex d-lg-none">
			</v-app-bar-nav-icon>
		</v-app-bar>
		<v-navigation-drawer
			v-model="drawer"
			width="200"
			absolute
			temporary
			color="#121e42">
			<v-list
				nav
				dense>
				<v-list-item-group>
					<v-list-item to="/">
						<v-icon
							dense
							color="#51b1fe"
							class="mr-2"
							>mdi-circle</v-icon
						>
						<v-list-item-title>Home</v-list-item-title>
					</v-list-item>

					<v-list-item to="/select-request">
						<v-icon
							dense
							color="#51b1fe"
							class="mr-2"
							>mdi-circle</v-icon
						>
						<v-list-item-title>Saved Requests</v-list-item-title>
					</v-list-item>
					<v-list-item
						v-if="this.$msal.user.isAdmin"
						to="/requests">
						<v-icon
							dense
							color="#51b1fe"
							class="mr-2"
							>mdi-circle</v-icon
						>
						<v-list-item-title>Process Requests</v-list-item-title>
					</v-list-item>
					<v-list-item 
					v-if="this.$msal.user.isSys"
					to="/test">
						<v-icon
							dense
							color="#51b1fe"
							class="mr-2"
							>mdi-circle</v-icon
						>
						<v-list-item-title>Test Rig</v-list-item-title>
					</v-list-item>
					<!-- change v-if - need to add isLoading state -->
					<div v-if="true">
						<v-list-item
							v-if="!this.$msal.user.isAuthenticated"
							@click="login">
							<v-icon
								dense
								color="#51b1fe"
								class="mr-2"
								>mdi-login-variant</v-icon
							>
							<v-list-item-title>Login</v-list-item-title>
						</v-list-item>
						<v-list-item
							v-if="this.$msal.user.isAuthenticated"
							@click="logout">
							<v-icon
								dense
								color="#51b1fe"
								class="mr-2"
								>mdi-logout-variant</v-icon
							>
							<div>
								<v-list-item-title>Logout</v-list-item-title>
								<v-list-item-subtitle>{{ this.$msal.user.email }}</v-list-item-subtitle>
							</div>
						</v-list-item>
					</div>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
export default {
	data: () => ({
		darkMode: false,
		drawer: false,
		tab: null,
	}),
	methods: {
		toggleDarkMode: function () {
			this.$vuetify.theme.themes.dark.background = '#FF0000'
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark
			this.darkMode = !this.darkMode
		},
		login() {
			this.$msal.signIn()
		},
		logout() {
			this.$msal.signOut()
			this.$router.replace('/')
		},
	},
	computed: {
		switchLabel: function () {
			return this.darkMode ? 'mdi-white-balance-sunny' : 'mdi-weather-night'
		},
	},
}
</script>
<style lang="scss" scoped>
header {
	background: #121e42 !important;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
	color: $ashiawhite;
}

.v-tab--active {
	color: white;
}
.v-navigation-drawer .v-list:not(.v-select-list) {
	background: #121e42 !important;
}
</style>
