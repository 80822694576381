export default {
  keyStatuses(state) {
    return state.keyStatuses
  },
  preFormQuestions(state) {
    return JSON.parse(JSON.stringify(state.preFormQuestions))
  },
  requestTypes(state) {
    return state.requestTypes
  },
  requestDetail: (state) => (selector) => {
    return state.request[selector]
  },
  requestFormAnswers(state) {
    return state.request.formAnswers
  },
  request(state) {
    return state.request
  },
  getRequests(state) {
    return JSON.parse(JSON.stringify(state.requests))
  },
  getAdminRequests(state) {
    return JSON.parse(JSON.stringify(state.adminRequests))
  },
  getRequestById: (state) => (id) => {
    return state.dummyRequests.filter(req => req.id === id)
  },
  getRequestByDevopsId: (state) => (id) => {
    return state.requests.filter(req => req.devopsID === id)
  },
  getValidationRefs(state) {
    return state.validationRefs
  }
}