import Vue from 'vue'
import Vuex from 'vuex'

import requestsModule from './requests/index'
import servicesModule from './services/index'
import userModule from './user/index'
import devopsModule from './devops/index'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    services: servicesModule,
    requests: requestsModule,
    user: userModule,
    devops: devopsModule
  },
})
