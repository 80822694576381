import dOMutations from './mutations'
import dOGetters from './getters'
import dOActions from '@/store/devops/actions'

export default {
  namespaced: true,
  state: {
    isUpdateRequired: false,
    devOpsId: null,
    devOpsRequests: [],
    devOpsAdminRequests: {
      pendingRequests: [],
      approvedRequests: [],
      completedRequests: [],
    },
    devOpsComments: [],
    summaryFields: [
      "System.Id",
      "System.Title",
      "System.Description",
      "System.BoardColumn",
      "System.CreatedDate",
      "System.CreatedBy",
      "System.AssignedTo",
      "System.CreatedBy",
      "Microsoft.VSTS.Common.Severity",

      // This uploaded files is different from epic uploaded files
      "Custom.UploadedFiles",
      "Microsoft.VSTS.Scheduling.TargetDate",
      "System.Description",
      // Feature Change
      "System.WorkItemType",
      "System.TeamProject",
      "Custom.Project",
      "Custom.ChangeType",
      "Custom.ReasonforChange",
      "Microsoft.VSTS.Common.AcceptanceCriteria",
      // Bug Report
      "Microsoft.VSTS.TCM.ReproSteps",
      "Custom.Linktobug",
      "Custom.OperatingSystems",
      "Custom.Browser",
      "Custom.Screenshots",
      // Business Importance
      "Custom.WSJF",
      "Microsoft.VSTS.Common.BusinessValue",
      "Microsoft.VSTS.Common.TimeCriticality",
      "Custom.RiskorOpportunity",
      "Microsoft.VSTS.Scheduling.Effort",
      // Epic additions
      "Custom.FeatureList",
      "Custom.MarketAnalysis",
      "Custom.Competitors",
      "Custom.BusinessValueAnalysis",
      "Custom.SupportingFileLinks",
      // Feature additions
      "Custom.Project",
      "Custom.UserActions",
      "Custom.ActionValues",
      "Custom.ActionExamples",
      // Small Task additions
      "Custom.ReasonforTask",

      //Comments
      "System.CommentCount"
    ],
    // changeRequestFields: [
    //   "System.Id",
    //   "System.Title",
    //   "System.Description",
    //   "System.BoardColumn",
    //   "System.CreatedDate",
    //   "System.CreatedBy.displayName",
    //   "System.AssignedTo.displayName",
    //   "System.CreatedBy.imageUrl",
    //   "Microsoft.VSTS.Common.Severity",
    //   "Microsoft.VSTS.Common.AcceptanceCriteria",
    //   "Microsoft.VSTS.Scheduling.TargetDate"
    // ],
    // bugRequestFields: [
    //   "System.Description",
    //   "Microsoft.VSTS.TCM.ReproSteps"
    // ],
  },
  getters: dOGetters,
  mutations: dOMutations,
  actions: dOActions
}