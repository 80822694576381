export default {
  setIsUpdateRequired(state, payload) {
    state.isUpdateRequired = payload
  },
  setDevopsId(state, payload) {
    state.devOpsId = payload
  },
  clearDevopsId(state) {
    state.devOpsId = null
  },
  setDevOpsRequests(state, payload) {
    state.devOpsRequests = payload.map(el => el.fields)
  },
  clearDevOpsRequests(state) {
    state.devOpsRequests = []
  },
  setDevOpsAdminRequests(state, payload) {
    state.devOpsAdminRequests[payload.name] = payload.data
  },
  clearDevOpsAdminRequests(state, payload) {
    state.devOpsAdminRequests[payload] = []
  },
  setDevOpsComments(state, payload) {
    state.devOpsComments = payload
  },
  clearDevOpsComments(state) {
    state.devOpsComments = []
  }
}