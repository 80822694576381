import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { authGuard } from '@/auth/msal/authGaurd'
import { adminGuard } from '@/auth/msal/adminGuard'
import { sysGuard } from '@/auth/msal/sysGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: authGuard
  },
  // {
  //   path: '/edit-request',
  //   name: 'edit-request',
  //   component: () => import('../views/EditRequestView')
  // },
  {
    path: '/edit-request/:requestId',
    name: 'edit-request',
    component: () => import('../views/EditRequestView'),
    props: true,
    beforeEnter: authGuard
  },
  {
    path: '/process-request/:requestId',
    name: 'process-request',
    component: () => import('../views/ProcessRequestView.vue'),
    props: true,
    beforeEnter: adminGuard
  },
  {
    path: '/requests',
    name: 'requests',
    component: () => import('../views/RequestsListView.vue'),
    beforeEnter: adminGuard
  },
  {
    path: '/select-request',
    name: 'select-request',
    component: () => import('../views/SelectRequestView'),
    beforeEnter: authGuard
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../components/tools/GetTestData'),
    beforeEnter: sysGuard
  },
  {
    path: '/unauthorised',
    name: '401',
    component: () => import('../views/Unauthorised')
  },
  {
    path: '/signin',
    name: 'sign-in',
    component: () => import('../views/LandingSignIn.vue'),
  },
  {
    path: '/:notFound(.*)',
    name: '404',
    component: () => import('../views/NotFound')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
