<template>
	<v-snackbar
		:top="snackbar.positionToggle"
		:bottom="!snackbar.positionToggle"
		app
		:color="snackbar.color"
		:timeout="snackbar.timeout"
		v-model="snackbar.show">
		{{ snackbar.text }}
		<template v-slot:action="{ attrs }">
			<v-btn
				color="white"
				text
				v-bind="attrs"
				@click="$store.commit('services/hideSnackbar')">
				Close
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	computed: {
		...mapGetters({
			snackbar: 'services/snackbarState',
		}),
	},
}
</script>

<style></style>
