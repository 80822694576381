<template>
	<div>
		<div class="timeline-section">
			<v-timeline dense>
				<v-timeline-item
					v-for="message in messages"
					:key="message.createdDate"
					fill-dot
					small>
					<template v-slot:icon>
						<v-avatar
							size="32"
							class="">
							<img
								alt="user"
								:src="message.createdBy.imageUrl" />
						</v-avatar>
					</template>
					<div>
						<div class="font-weight-normal">
							<strong>{{ message.createdBy.displayName }}</strong> | {{ diffInTimeDate(message.createdDate) }}
						</div>
						<span v-html="message.text"></span>
					</div>
				</v-timeline-item>
			</v-timeline>
		</div>

		<v-divider class="mb-4"></v-divider>
		<VEmojiPicker
			class="emoji-dialog"
			v-show="showDialog"
			labelSearch="Search"
			lang="pt-BR"
			@select="onSelectEmoji" />
		<v-textarea
			rows="1"
			outlined
			name="comment-input"
			label="Comment"
			auto-grow
			hide-details
			v-model="valueInput">
		</v-textarea>

		<v-layout class="ma-0">
			<v-layout class="align-center mb-0 mx-0">
				<v-btn
					class="ma-0"
					text
					icon>
					<v-icon @click="toogleDialogEmoji">mdi-emoticon</v-icon>
				</v-btn>
				<v-spacer />
				<v-btn
					class="ma-0"
					text
					icon
					@click.stop="sendComment(valueInput)">
					<v-icon color="white"> mdi-send </v-icon>
				</v-btn>
			</v-layout>
		</v-layout>
	</div>
</template>

<script>
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { VEmojiPicker, emojisDefault, categoriesDefault } from 'v-emoji-picker'

export default {
	name: 'discussion-section',
	props: ['id'],
	emits: ['updateRequests'],
	components: {
		VEmojiPicker,
	},
	data: () => ({
		valueInput: '',
		showDialog: false,
	}),
	computed: {
		messages() {
			return this.$store.getters['devops/getDevOpsComments']
		},
	},
	methods: {
		toogleDialogEmoji() {
			this.showDialog = !this.showDialog
		},
		onSelectEmoji(emoji) {
			this.valueInput += emoji.data
			// Optional
			this.toogleDialogEmoji()
		},
		async sendComment(text) {
			await this.$store.dispatch('devops/postDevOpsComment', {
				id: this.id,
				text: text,
			})
			await this.$store.dispatch('devops/getDevOpsComments', { id: this.id })
			this.$emit('updateRequests')
			this.valueInput = ''
		},
		diffInTimeDate(date) {
			if (!date) {
				return null
			}
			return dayjs(date).fromNow()
		},
	},
	created() {
		dayjs.extend(relativeTime)
	},
}
</script>
<style lang="scss" scoped>
.emoji-picker {
	position: absolute;
	z-index: 99;
	margin-top: -440px;
	width: 308px !important;
	// height: 450px;
	--ep-color-bg: rgba(18, 30, 66, 1) !important;
	--ep-color-border: rgba(18, 30, 66, 1) !important;
	--ep-color-active: #51f0f1 !important;
	border-radius: 5px !important;
}
.timeline-section {
	display: flex;
	flex-direction: column-reverse;
	height: 30vh;
	overflow: auto;
	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px rgba(18, 30, 66, 0.3);
		border-radius: 10px;
		margin-left: 5px;
	}

	&::-webkit-scrollbar-thumb {
		background: rgba(18, 30, 66, 0.3);
		border-radius: 10px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: rgba(18, 30, 66, 0.4);
	}
}
.v-timeline::before {
	top: 55px;
	height: calc(100% - 55px);
}
</style>
