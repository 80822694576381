export default {
  clearRequestData(state) {
    state.request.id = null,
      state.request.requestTypeId = null,
      state.request.title = '',
      state.request.summary = '',
      state.request.detail = [],
      state.request.submissionState = 0,
      state.request.score = {
        "final": null,
        "value": null,
        "criticality": null,
        "risk": null,
        "effort": null
      },
      state.request.devopsID = null
  },
  setPreFormQuestions(state, payload) {
    state.preFormQuestions.questions[payload.index].args.items = payload.data
  },
  setRequestTypes(state, payload) {
    payload.forEach(el => {
      let elType = state.mapWITypeToRequestTypeId.find(type => type.id === el.id).wIType
      state.requestTypes.push({...el, wIType: elType})
    })
  },
  setRequestDetail(state, payload) {
    state.request[payload.type] = payload.value
  },
  setRequestAnswer(state, payload) {
    state.request.detail[payload.quIndex].answer = payload.value
  },
  setRequest(state, payload) {
    state.request.id = payload.id,
      state.request.requestTypeId = payload.requestTypeID,
      state.request.title = payload.title,
      state.request.summary = payload.summary,
      state.request.detail = payload.detail,
      state.request.submissionState = payload.submissionState,
      state.request.score.final = payload.score.final
      state.request.score.value = payload.score.value,
      state.request.score.criticality = payload.score.criticality,
      state.request.score.risk = payload.score.risk,
      state.request.score.effort = payload.score.effort
      state.request.devopsID = payload.devopsID
  },
  setRequests(state, payload) {
    state.requests = payload
  },
  addAdminRequests(state, payload) {
    state.adminRequests[payload.name] = payload.data
  },
  clearAdminRequests(state, payload) {
    state.adminRequests[payload] = []
  },
  setValidationRefs(state, payload) {
    state.validationRefs = payload
  }
}