<template>
	<div class="mt-4">
		<v-form>
			<div class="">
				<v-tooltip
					max-width="80%"
					color="#5054f1"
					top
					v-model="businessTip">
					<template v-slot:activator="{ attrs }">
						<div class="mb-4 d-flex">
							<v-select
								v-model="business.select"
								:items="business.items"
								item-text="description"
								item-value="value"
								label="Business Weight"
								hide-details
								return-object
								outlined
								class="select-width">
							</v-select>

							<v-icon
								v-bind="attrs"
								right
								@mouseenter="businessTip = true"
								@mouseleave="businessTip = false"
								>mdi-information</v-icon
							>
						</div>
					</template>
					<span>
						It ranks your jobs by their relative importance to the user and potential impact on revenue.
						Answer the questions.
					</span>
				</v-tooltip>

				<v-tooltip
					max-width="80%"
					color="#5054f1"
					top
					v-model="criticallyTip">
					<template v-slot:activator="{ attrs }">
						<div class="d-flex mb-4">
							<v-select
								v-model="criticality.select"
								:items="criticality.items"
								item-text="description"
								item-value="value"
								label="Criticality Weight"
								hide-details
								outlined
								return-object
								class="select-width">
							</v-select>
							<v-icon
								v-bind="attrs"
								right
								@mouseenter="criticallyTip = true"
								@mouseleave="criticallyTip = false"
								>mdi-information</v-icon
							>
						</div>
					</template>
					<span>
						It ranks the jobs by urgency e.g. deadlines like new regulations, tax reporting, seasonal
						promotions, etc.
					</span>
				</v-tooltip>

				<div class="d-flex mb-4">
					<v-tooltip
						v-model="riskTip"
						max-width="80%"
						color="#5054f1"
						top>
						<template v-slot:activator="{ attrs }">
							<v-select
								v-model="risk.select"
								:items="risk.items"
								item-text="description"
								item-value="value"
								label="Risk Weight"
								return-object
								outlined
								hide-details
								class="select-width">
							</v-select>
							<v-icon
								v-bind="attrs"
								right
								@mouseenter="riskTip = true"
								@mouseleave="riskTip = false">
								mdi-information
							</v-icon>
						</template>
						<span>
							Tasks to avoid or reduce technological or business risks. Examples include code refactoring,
							database update, security, or tax audit - something that you must do to ensure the healthy
							operating of a product. It helps you highlight jobs that may not bring revenue immediately but
							benefit the long run. Some solutions will help you eliminate technical or legal risks and save
							you money later.
						</span>
					</v-tooltip>
				</div>

				<div class="d-flex mb-4">
					<v-tooltip
						v-model="jobTip"
						max-width="80%"
						color="#5054f1"
						top>
						<template v-slot:activator="{ attrs }">
							<v-select
								v-model="job.select"
								:items="job.items"
								item-text="description"
								item-value="value"
								label="Job Weight"
								return-object
								outlined
								hide-details
								class="select-width">
							</v-select>
							<v-icon
								v-bind="attrs"
								right
								@mouseenter="jobTip = true"
								@mouseleave="jobTip = false">
								mdi-information
							</v-icon>
						</template>
						<span>
							It ranks the jobs by urgency e.g. deadlines like new regulations, tax reporting, seasonal
							promotions, etc.
						</span>
					</v-tooltip>
				</div>
			</div>
			<div>
				<h3>WSJF score = {{ result }}</h3>
				<v-divider class="my-5"></v-divider>
				<div>
					<v-btn
						v-if="requestStatus === 'Submitted'"
						class="mr-5 mb-3"
						rounded
						color="#5054f1"
						@click="handleAcknowledgeClick">
						Acknowledge
					</v-btn>
					<v-btn
						class="mr-5 mb-3"
						rounded
						color="#5054f1"
						@click="handleScoreClick">
						Add scores
					</v-btn>
				</div>
			</div>
		</v-form>
	</div>
</template>

<script>
export default {
	props: ['requestStatus'],
	emits: ['addScores', 'acknowledgeRequest'],
	name: 'Calculator',
	computed: {
		result() {
			let COD = this.business.select.value + this.criticality.select.value + this.risk.select.value
			let result = COD / this.job.select.value
			return result
		},
		updatedScores() {
			let scores = {
				wsjf: this.result,
				value: this.business.select.value,
				criticality: this.criticality.select.value,
				risk: this.risk.select.value,
				effort: this.job.select.value,
			}
			return scores
		},
	},
	methods: {
		handleScoreClick() {
			this.$emit('addScores', this.updatedScores)
		},
		handleAcknowledgeClick() {
			this.$emit('acknowledgeRequest')
		},
	},
	data: () => ({
		businessTip: false,
		criticallyTip: false,
		riskTip: false,
		jobTip: false,
		business: {
			select: {
				description: '1- No user-business value at all; this task is related to something else.',
				value: 1,
			},
			items: [
				{
					description: '1 - No user-business value at all; this task is related to something else.',
					value: 1,
				},
				{ description: '2 - The lowest business impact and confidence.', value: 2 },
				{ description: '3 - Low business impact and confidence.', value: 3 },
				{ description: '5 - Moderate business impact, moderate confidence.', value: 5 },
				{ description: '8 - High business impact and confidence.', value: 8 },
				{ description: '13 - Very high business impact and confidence.', value: 13 },
				{ description: '21 - The highest business impact and confidence.', value: 21 },
			],
		},
		criticality: {
			select: {
				description: 'Not a time-critical task at all.',
				value: 1,
			},
			items: [
				{ description: 'Not a time-critical task at all.', value: 1 },
				{
					description:
						'It can wait till the next estimation cycle (after score expiration every five interactions.',
					value: 2,
				},
				{ description: 'Moderate urgency, it can wait for three sprints (about six weeks).', value: 3 },
				{ description: 'Moderate business impact, moderate confidence.', value: 5 },
				{ description: 'It can wait for two sprints (about four weeks).', value: 8 },
				{ description: 'It can wait for one sprint (about two weeks).', value: 13 },
				{ description: 'The highest urgency, you must take that issue to the next sprint.', value: 21 },
			],
		},
		risk: {
			select: {
				description: 'It doesn’t provide any risk reduction at all.',
				value: 1,
			},
			items: [
				{ description: 'It doesn’t provide any risk reduction at all.', value: 1 },
				{
					description: 'Reduce the likelihood of the lowest risk and lowest severity probability.',
					value: 2,
				},
				{ description: 'Reduce the likelihood of a low-risk, low severity probability.', value: 3 },
				{
					description: 'Reduce the likelihood of moderate-risk and moderate severity probability.',
					value: 5,
				},
				{
					description: 'Reduce the likelihood of a moderate-high risk, high severity probability.',
					value: 8,
				},
				{
					description: 'Reduce the likelihood of a very high-risk, very high severity probability.',
					value: 13,
				},
				{
					description:
						'Can reduce the risk of a highly impactful event, a disaster that is most likely to happen.',
					value: 21,
				},
			],
		},
		job: {
			select: {
				description:
					'No effort at all is required. We can’t divide by zero, so our Job Size estimation should start from 1.',
				value: 1,
			},
			items: [
				{
					description:
						'No effort at all is required. We can’t divide by zero, so our Job Size estimation should start from 1.',
					value: 1,
				},
				{
					description:
						'The estimation baseline. 80% probability that one day is enough to code and one day to test and validate for a two-week iteration.',
					value: 2,
				},
				{ description: 'A task about a quarter of your sprint efforts.', value: 3 },
				{
					description:
						'Something half of your iteration. 80% probability that the task will take 5 workdays to code and one day to test and validate for a two-week iteration.',
					value: 5,
				},
				{
					description:
						'80% probability that a task will be developed and tested within two weeks (one iteration/sprint).',
					value: 8,
				},
				{ description: 'Between one and two iterations.', value: 13 },
				{
					description:
						'“Buzzlight estimations,” something that takes two sprints or even more (“to infinity and beyond”).',
					value: 21,
				},
			],
		},
	}),
}
</script>
<style lang="scss" scoped>
.v-messages__message {
	line-height: 1.5;
	font-size: 14px;
	color: rgba(255, 255, 255, 0.7);
}
.select-width {
	max-width: 95%;
}
// .calc-select {
// 	width: 100%;
// }
// * {
// 	border: 1px red solid;
// }
</style>
