import Vue from 'vue'
import store from '@/store/index'
import { PublicClientApplication } from '@azure/msal-browser';

let instance
/** Returns the current instance of MSAL*/
export const getInstance = () => instance
/** Creates an instance of the MSAL. If one has already been created, it returns that instance */
export const useMsal = () => {
  if (instance) return instance
  // The 'instance' is simply a Vue object
  instance = new Vue({
    data () {
      return {
        loading: false,
        user: {
          id: '',
          email: 'Not Logged In',
          name: '',
          isAdmin: false,
          isAuthenticated: false,
          isSys: false
        },
        msalClient: null,
        popupOpen: false,
        error: null
      }
    },
    async created() {
      
      this.loading = true
      // create a new msal instance
      this.msalClient = new PublicClientApplication(
        store.getters['user/msalConfig']
      );
      // create handler for redirects
      try{
        this.loading = true
        const res = await this.msalClient.handleRedirectPromise()
        if (res) {
          this.handleResponse(res)
        } 
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      } 
    },
    methods: {
      async setUser(account) {
        	this.user = {
        		id: account.localAccountId,
        		email: account.username,
        		name: account.name,
        		isAdmin: this.getIsAdmin(account),
        		isAuthenticated: this.getIsAuth(account),
            isSys: this.getIsSys(account)
        	}
        	// store.dispatch('user/setUser', userData)
      },
      getIsAuth(account) {
        // Check roles contain either standard or admin
        if (account.idTokenClaims.roles.includes('access_as_user') || 
        account.idTokenClaims.roles.includes('access_as_admin')
        ) {
          // getAccessToken()
          return true
        } else {
          return false
        }
      },
      getIsAdmin(account) {
        // Check roles contain either standard or admin
        if (account.idTokenClaims.roles.includes('access_as_admin')) {
          // getDOpToken()
          return true
        } else {
          return false
        }
      },
      getIsSys(account) {
        // Check roles contain either standard or admin
        if (account.idTokenClaims.roles.includes('access_as_system')) {
          // getDOpToken()
          return true
        } else {
          return false
        }
      },
      handleResponse(response) {
        /**
         * To see the full list of response object properties, visit:
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
         */
        if (response !== null) {
            const account = response.account
            // console.log('Response Handler: ', account);
            this.setUser(account)
            this.loading = false
        } else {
            this.selectAccount();
        }
      },
      selectAccount() {
        const currentAccounts = this.msalClient.getAllAccounts();
        if (!currentAccounts || currentAccounts.length < 1) {
            return;
        } else if (currentAccounts.length > 1) {
            // Add your account choosing logic here
            console.warn("Multiple accounts detected.");
        } else if (currentAccounts.length === 1) {
            const account = currentAccounts[0];
            console.log('Select Account: ',account);
            this.setUser(account)
            this.loading = false
            // getAccessToken()
            // getDOpToken()
        }
      },
      async signIn() {
        await this.msalClient.loginRedirect();
      },
      async signOut() {
        let request = {
          forceRefresh: false,
          account: await this.msalClient.getAccountByUsername(this.user.email)
        };
        try { 
          await this.msalClient
          .logout(request)
          .then(() => {
            this.msalClient = {}
            // this.$emitter.emit('logout', 'logging out');
            // this.$store.dispatch('clearUserData')
          })
        } catch (error) {
          console.error(error);
        } finally {
          this.$router.replace('/')
        }
      },
      async getAccessToken() {
        let request = {
          scopes: [process.env.VUE_APP_AUTH_APP_SCOPE],
          forceRefresh: true,
          account: this.msalClient.getAccountByUsername(this.user.email)
        };
        let tokenResponse
        try {
          tokenResponse = await this.msalClient.acquireTokenSilent(request);
          // store.commit('user/setAccessToken', tokenResponse.accessToken);
          // console.log(`Access token acquired via silent auth`, tokenResponse)
        } catch (error) {
            console.error( 'Silent token acquisition failed. Using interactive mode' );
            tokenResponse = await this.msalClient.acquireTokenPopup(request);
            // this.$store.commit('user/setAccessToken', tokenResponse.accessToken);
            // console.log(`Access token acquired via interactive auth ${tokenResponse}`)
        }
        return tokenResponse.accessToken
      },
      async getDOpToken () {
        // const user = store.getters['user/user']

        let request = {
          scopes: [process.env.VUE_APP_AUTH_DEVOPS_SCOPE],
          forceRefresh: false,
          account: this.msalClient.getAccountByUsername(this.user.email)
        };
        let tokenResponse
        try {
          tokenResponse = await this.msalClient.acquireTokenSilent(request);
          // store.commit('user/setDOpToken', tokenResponse.accessToken);
          // console.log(`Access token acquired via silent auth`, tokenResponse)
        } catch (error) {
            console.error( 'Silent token acquisition failed. Using interactive mode' );
            tokenResponse = await this.msalClient.acquireTokenPopup(request);
            // this.$store.commit('user/setDOpToken', tokenResponse.accessToken);
            // // console.log(`Access token acquired via interactive auth ${tokenResponse}`)
        }
        return tokenResponse
      }
    }
  })
  return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const MsalPlugin = {
  install (Vue) {
    Vue.prototype.$msal = useMsal()
  }
}