export default {
  isUpdateRequired(state) {
    returnstate.isUpdateRequired
  },
  getDevOpsId(state) {
    return state.devOpsId
  },
  getDevOpsRequests(state) {
    return state.devOpsRequests
  },
  getDevOpsAdminRequests(state) {
    return state.devOpsAdminRequests
  },
  getSummaryFields(state) {
    return state.summaryFields
  },
  getChangeRequestFields(state) {
    return state.changeRequestFields
  },
  getDevOpsRequestById: (state) => (id) => {
    return state.devOpsRequests.filter(req => req['System.Id'] === id)
  },
  getDevOpsAdminRequestById: (state) => (id) => {
    // Squish all the admin requests into a single array
    let allRequests = []
    for (let key in state.devOpsAdminRequests) {
      allRequests.push(...state.devOpsAdminRequests[key])
    }
    return allRequests.filter(req => req.fields['System.Id'] === id)
  },
  getDevOpsComments(state) {
    return state.devOpsComments
  },
}
