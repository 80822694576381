import axios from 'axios'
import { getInstance } from '@/auth/msal/msal'

export default {
  async loadRequestTypes(context) {
    // GET Request Types data as an Array and set Store copy
    let requestTypes = []
    const API_KEY = process.env.VUE_APP_APIKEY
    URL = process.env.VUE_APP_APIURL + 'workflow/admin/request-types'
    const authService = getInstance()
    const accessToken = await authService.getAccessToken()
    const ops = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'x-api-key': API_KEY
      },
      url: URL
    }
    // console.log('Token in Load Request Types: ', accessToken);
    await axios(ops)
      .then(res => {
        requestTypes = res.data
        context.commit('setRequestTypes', requestTypes)
        context.commit('setPreFormQuestions', { index: 0, data: requestTypes.map(el => { return { id: el.id, text: el.title } }) })
      })
      .catch(function (error) {
        if (error.response) {
          context.dispatch('services/UpdateSnackbar', { text: 'Contact Support: ' + error.response.data.message, color: 'error' }, { root: true })
          console.log('Response Data: ', error.response.data);
          console.log('Response Status: ', error.response.status);
          console.log('Response Headers: ', error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
  },
  async loadQuestions(context, data) {
    // Get Question Set as an Array and set Store copy
    let questionData = []
    const API_KEY = process.env.VUE_APP_APIKEY
    URL = process.env.VUE_APP_APIURL + 'workflow/admin/request-types/components'
    const authService = getInstance()
    const accessToken = await authService.getAccessToken()
    const ops = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'x-api-key': API_KEY
      },
      url: URL
    }
    await axios(ops)
      .then(res => {
        questionData = res.data.filter(el => el.requestTypeID === data.reqTypeID)
        context.commit('setRequestDetail', { type: 'detail', value: questionData[0].questions })
      })
      .catch(function (error) {
        if (error.response) {
          context.dispatch('services/UpdateSnackbar', { text: 'Contact Support: ' + error.response.data.message, color: 'error' }, { root: true })
          console.log('Response Data: ', error.response.data);
          console.log('Response Status: ', error.response.status);
          console.log('Response Headers: ', error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
  },
  async loadRequests(context, data) {
    const API_KEY = process.env.VUE_APP_APIKEY
    const URL = process.env.VUE_APP_APIURL + 'workflow/users/requests'
    const authService = getInstance()
    const accessToken = await authService.getAccessToken()
    const ops = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'x-api-key': API_KEY
      },
      url: URL
    }
    let res = null
    res = await axios(ops)
      .catch(function (error) {
        if (error.response) {
          context.dispatch('services/UpdateSnackbar', { text: 'Contact Support: ' + error.response.data.message, color: 'error' }, { root: true })
          console.log('Response Data: ', error.response.data);
          console.log('Response Status: ', error.response.status);
          console.log('Response Headers: ', error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
    console.log('loadRequests (' + data.filter + ') - GET result: ' + res.request.status + ' ' + res.request.statusText)
    context.commit('setRequests', res.data[data.filter])
    // console.log(JSON.stringify(res.data[data.filter], null, 2))
  },
  async loadAdminRequests(context, data) {
    const API_KEY = process.env.VUE_APP_APIKEY
    let URL = process.env.VUE_APP_APIURL + 'workflow/admin/requests'
    if (data.filter) { URL = URL + '?filter=' + data.filter.toString() }
    const authService = getInstance()
    const accessToken = await authService.getAccessToken()
    const ops = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'x-api-key': API_KEY
      },
      url: URL
    }
    let res = null
    res = await axios(ops)
      .catch(function (error) {
        if (error.response) {
          context.dispatch('services/UpdateSnackbar', { text: 'Contact Support: ' + error.response.data.message, color: 'error' }, { root: true })
          console.log('Response Data: ', error.response.data);
          console.log('Response Status: ', error.response.status);
          console.log('Response Headers: ', error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
    console.log('loadRequests (' + data.filter + ') - GET result: ' + res.request.status + ' ' + res.request.statusText)
    context.commit('setRequests', res.data[data.filter])


    // console.log(JSON.stringify(res.data, null, 2))
  },
  async loadRequestById(context, data) {
    // clear old request data
    context.commit('clearRequestData')
    const API_KEY = process.env.VUE_APP_APIKEY
    URL = process.env.VUE_APP_APIURL + 'workflow/users/requests/' + data.requestId
    const authService = getInstance()
    const accessToken = await authService.getAccessToken()
    const ops = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'x-api-key': API_KEY
      },
      url: URL
    }
    await axios(ops)
      .then(res => res.data)
      .then(data => {
        context.commit('setRequest', data)
        // console.log(JSON.stringify(data, null, 2))
      }).catch(function (error) {
        if (error.response) {
          context.dispatch('services/UpdateSnackbar', { text: 'Contact Support: ' + error.response.data.message, color: 'error' }, { root: true })
          console.log('Response Data: ', error.response.data);
          console.log('Response Status: ', error.response.status);
          console.log('Response Headers: ', error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
  },
  async loadAdminRequestById(context, data) {
    // clear old request data
    context.commit('clearRequestData')
    const API_KEY = process.env.VUE_APP_APIKEY
    URL = process.env.VUE_APP_APIURL + 'workflow/admin/requests/' + data.reqId
    const authService = getInstance()
    const accessToken = await authService.getAccessToken()
    const ops = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'x-api-key': API_KEY
      },
      url: URL
    }
    await axios(ops)
      .then(res => res.data)
      .then(data => {
        context.commit('setRequest', data)
        // console.log(JSON.stringify(data, null, 2))
      }).catch(function (error) {
        if (error.response) {
          context.dispatch('services/UpdateSnackbar', { text: 'Contact Support: ' + error.response.data.message, color: 'error' }, { root: true })
          console.log('Response Data: ', error.response.data);
          console.log('Response Status: ', error.response.status);
          console.log('Response Headers: ', error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
  },
  async submitRequest(context) {
    const requestData = context.getters.request
    // console.log(JSON.stringify(requestData));
    const API_KEY = process.env.VUE_APP_APIKEY
    URL = process.env.VUE_APP_APIURL + 'workflow/users/requests'
    const authService = getInstance()
    const accessToken = await authService.getAccessToken()
    const ops = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'x-api-key': API_KEY
      },
      url: URL,
      data: JSON.stringify(requestData)
    }

    const res = await axios(ops)
      .catch(function (error) {
        if (error.response) {
          context.dispatch('services/UpdateSnackbar', { text: 'Contact Support: ' + error.response.data.message, color: 'error' }, { root: true })
          console.log('Response Data: ', error.response.data);
          console.log('Response Status: ', error.response.status);
          console.log('Response Headers: ', error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
    console.log('submitRequest - postRequestData: ', 'POST request result: ' + res.request.status + ' ' + res.request.statusText)
    const returnedData = res.data
    context.commit('setRequestDetail', { type: 'id', value: returnedData.id })
  },
  async updateRequest(context, data) {
    const requestData = context.getters.request
    // console.log(requestData);
    const API_KEY = process.env.VUE_APP_APIKEY
    URL = process.env.VUE_APP_APIURL + 'workflow/admin/requests/' + data.reqId
    const authService = getInstance()
    const accessToken = await authService.getAccessToken()
    const ops = {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'x-api-key': API_KEY
      },
      url: URL,
      data: requestData
    }

    const res = await axios(ops)
      .catch(function (error) {
        if (error.response) {
          context.dispatch('services/UpdateSnackbar', { text: 'Contact Support: ' + error.response.data.message, color: 'error' }, { root: true })
          console.log('Response Data: ', error.response.data);
          console.log('Response Status: ', error.response.status);
          console.log('Response Headers: ', error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
    console.log('PUT request result: ', res.request.status, res.request.statusText)
    context.dispatch('services/UpdateSnackbar', { text: 'Request Complete', color: 'success' }, { root: true })
  },
  async updateRequest(context, data) {
    const requestData = context.getters.request
    // console.log(requestData);
    const API_KEY = process.env.VUE_APP_APIKEY
    URL = process.env.VUE_APP_APIURL + 'workflow/admin/requests/' + data.reqId
    const authService = getInstance()
    const accessToken = await authService.getAccessToken()
    const ops = {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'x-api-key': API_KEY
      },
      url: URL,
      data: requestData
    }

    const res = await axios(ops)
      .catch(function (error) {
        if (error.response) {
          context.dispatch('services/UpdateSnackbar', { text: 'Contact Support: ' + error.response.data.message, color: 'error' }, { root: true })
          console.log('Response Data: ', error.response.data);
          console.log('Response Status: ', error.response.status);
          console.log('Response Headers: ', error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
    console.log('PUT request result: ', res.request.status, res.request.statusText)
    context.dispatch('services/UpdateSnackbar', { text: 'Request Complete', color: 'success' }, { root: true })

  },
  async deleteRequest(context, data) {
    const API_KEY = process.env.VUE_APP_APIKEY
    URL = process.env.VUE_APP_APIURL + 'workflow/users/requests/' + data.reqId
    const authService = getInstance()
    const accessToken = await authService.getAccessToken()
    const ops = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'x-api-key': '5b3c6c7a-6acb-4605-80f5-919490439681'
      },
      url: URL,
    }

    const res = await axios(ops)
      .catch(function (error) {
        if (error.response) {
          context.dispatch('services/UpdateSnackbar', { text: 'Contact Support: ' + error.response.data.message, color: 'error' }, { root: true })
          console.log('Response Data: ', error.response.data);
          console.log('Response Status: ', error.response.status);
          console.log('Response Headers: ', error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
    console.log('DELETE request result: ', res.request.status, res.request.statusText)
    context.dispatch('services/UpdateSnackbar', { text: 'Request Deleted', color: 'success' }, { root: true })

  },
  async archiveRequest(context, data) {
    const API_KEY = process.env.VUE_APP_APIKEY
    const URL = process.env.VUE_APP_APIURL + 'workflow/admin/requests/' + data.reqId
    const authService = getInstance()
    const accessToken = await authService.getAccessToken()
    const ops = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'x-api-key': API_KEY
      },
      url: URL,
    }
    const res = await axios(ops)
      .catch(function (error) {
        if (error.response) {
          context.dispatch('services/UpdateSnackbar', { text: 'Contact Support: ' + error.response.data.message, color: 'error' }, { root: true })
          console.log('Response Data: ', error.response.data);
          console.log('Response Status: ', error.response.status);
          console.log('Response Headers: ', error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
    const delRequestData = 'DELETE request result: ' + res.request.status + ' ' + res.request.statusText
    console.log(delRequestData)
    context.dispatch('services/UpdateSnackbar', { text: 'Request Deleted', color: 'success' }, { root: true })
  },
  async completeRequest(context, data) {
    const requestData = context.getters.request
    const API_KEY = process.env.VUE_APP_APIKEY
    URL = process.env.VUE_APP_APIURL + 'workflow/admin/requests/' + data.reqId
    const authService = getInstance()
    const accessToken = await authService.getAccessToken()
    const ops = {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'x-api-key': API_KEY
      },
      url: URL,
      data: requestData,
    }
    const res = await axios(ops)
      .catch(function (error) {
        if (error.response) {
          context.dispatch('services/UpdateSnackbar', { text: 'Contact Support: ' + error.response.data.message, color: 'error' }, { root: true })
          console.log('Response Data: ', error.response.data);
          console.log('Response Status: ', error.response.status);
          console.log('Response Headers: ', error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
    const completeRequestData = 'PUT request result: ' + res.request.status + ' ' + res.request.statusText
    console.log(completeRequestData)

  },
  async hideOrArchiveRequest(context, data) {
    const API_KEY = process.env.VUE_APP_APIKEY
    const URL = process.env.VUE_APP_APIURL + 'workflow/users/requests/' + data.requestId
    const authService = getInstance()
    const accessToken = await authService.getAccessToken()
    const ops = {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'x-api-key': API_KEY
      },
      url: URL,
    }

    const res = await axios(ops)
      .catch(function (error) {
        if (error.response) {
          context.dispatch('services/UpdateSnackbar', { text: 'Contact Support: ' + error.response.data.message, color: 'error' }, { root: true })
          console.log('Response Data: ', error.response.data);
          console.log('Response Status: ', error.response.status);
          console.log('Response Headers: ', error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
    console.log('PUT request result for hideOrArchiveRequest: ', res.request.status, res.request.statusText)
    context.dispatch('services/UpdateSnackbar', { text: 'Request Archived', color: 'success' }, { root: true })
  },
  clearRequestData(context) {
    context.commit('clearRequestData')
  },
  resetAnswerData(context) {
    context.commit('resetAnswerData')
  },
  setRequestTypes(context, selector) {
    // replace with GET request to backend
    const typesData = context.getters.getTypesData(selector)
    // store data locally
    context.commit('setRequestTypes', typesData)
  },
  setRequestDetail(context, data) {
    const payload = {
      type: data.type,
      value: data.value
    }
    context.commit('setRequestDetail', payload)
  },
  setRequestAnswer(context, data) {
    const payload = {
      secIndex: data.secIndex,
      quIndex: data.quIndex,
      value: data.value
    }
    context.commit('setRequestAnswer', payload)
    return context.getters.request
  },
  setRequestById(context, reqId) {
    const selectedRequest = context.getters.getRequestById(reqId)[0]
    context.commit('setRequest', selectedRequest)
  },
  clearAdminRequests(context, data) {
    context.commit('clearAdminRequests', data)
  },
  setValidationRefs(context, data) {
    // console.log('setting refs');
    context.commit('setValidationRefs', data)
  }
}