<template>
	<div class="page-container">
		<h2 class="mb-8 text-center">Welcome {{ $msal.user.name }}, here are your requests below</h2>
		<Loading v-show="loading" />
		<RequestsListDOps
			v-show="!loading"
			:requests="this.requests"
			:isAdmin="false"
			:isDel="false"
			:hideCreate="false"
			:loading="loading"
			@updateRequests="handleUpdateRequests" />
	</div>
</template>

<script>
import RequestsListDOps from '@/components/requests/RequestsListDOps.vue'
import Loading from '@/components/ui/Loading.vue'
export default {
	components: { RequestsListDOps, Loading },
	data() {
		return {
			hideCreate: false,
			loading: true,
		}
	},
	computed: {
		summaryFields() {
			return this.$store.getters['devops/getSummaryFields']
		},
		requests() {
			return this.$store.getters['devops/getDevOpsRequests'] 
		},
		frogRequests() {
			return this.$store.getters['requests/getRequests'] ? this.$store.getters['requests/getRequests'] : []
		}
	},
	async beforeMount() {
		await this.getCurrentData()
	},
	methods: {
		async getCurrentData() {
			this.loading = true
			try{
				await this.$store.dispatch('requests/loadRequests', { filter: 'submittedRequests' })
				const usersIds = this.frogRequests.map((el) => el.devopsID)
				await this.$store.dispatch('devops/getRequestSummaries', { ids: usersIds, fields: this.summaryFields })
			} catch (error) {
				console.error(error);
			} finally {
				this.loading = false
			}			
		},
		handleUpdateRequests() {
			this.getCurrentData()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';
</style>
