<template>
	<v-container class="ma-0">
		<v-row>
			<v-col cols="12" class="pa-0">
				<v-skeleton-loader type="heading" width="100%" class="mb-4"></v-skeleton-loader>
				<v-skeleton-loader type="list-item" height="51px" width="100%" class="mb-4"></v-skeleton-loader>
				<v-skeleton-loader type="list-item" height="51px" width="100%" class="mb-4"></v-skeleton-loader>
				<v-skeleton-loader type="list-item" height="51px" width="100%" class="mb-4"></v-skeleton-loader>
				<v-skeleton-loader type="list-item" height="51px" width="100%" class="mb-4"></v-skeleton-loader>
				<v-skeleton-loader type="list-item" height="51px" width="100%" class="mb-4"></v-skeleton-loader>
				<v-skeleton-loader type="list-item" height="51px" width="100%" class="mb-4"></v-skeleton-loader>
				<v-skeleton-loader type="list-item" height="51px" width="100%" class="mb-4"></v-skeleton-loader>
				<!-- <v-skeleton-loader type="list-item" height="51px" width="100%" class="mb-4"></v-skeleton-loader>
				<v-skeleton-loader type="list-item" height="51px" width="100%" class="mb-4"></v-skeleton-loader>
				<v-skeleton-loader type="list-item" height="51px" width="100%" class="mb-4"></v-skeleton-loader> -->
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'Loading',
}
</script>

<style lang="scss">
.theme--dark.v-skeleton-loader .v-skeleton-loader__list-item {
	background: #121e4282 !important;
}
// ::v-deep .v-skeleton-loader > * {
// 	width: 100%;
// 	display: flex;
// 	flex-direction: column;
// }

// ::v-deep .v-skeleton-loader .v-skeleton-loader__bone {
// 	flex-grow: 1;
// }
</style>
