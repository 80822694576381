export default {
  namespaced: true,
  state: {
    snackbar: {
      show: false,
      text: '',
      color: '',
      positionToggle: true,
      timeout: null
    }
  },
  getters: {
    snackbarState(state) {
      return state.snackbar
    }
  },
  mutations: {
    showSnackbar(state, { text, color, position, time }) {
      let timeout = 0
      if (state.snackbar.show) {
        state.snackbar.show = false
      }
      if (position) {
        if (position === 'top') {
          state.snackbar.positionToggle = true
        } else if (position === 'bottom') {
          state.snackbar.positionToggle = false
        } else {
          state.snackbar.positionToggle = true
        }
      } else {
        state.snackbar.positionToggle = true
      }
      if (time) {
        state.snackbar.timeout = time
      } else {
        state.snackbar.timeout = 4000
      }
      state.snackbar.text = text
      state.snackbar.color = color
      state.snackbar.show = true
    },
    hideSnackbar(state) {
      state.snackbar.show = false
    }
  },
  actions: {
    UpdateSnackbar(context, { text, color, position, time }) {
      context.commit('showSnackbar', { text: text, color: color, position: position, time: time })
    },
  },
}
