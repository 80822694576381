import axios from 'axios'
import { getInstance } from '@/auth/msal/msal'

export default {
  setIsUpdateRequired(context, data) {
    context.commit('setIsUpdateRequired', data)
  },
  async createNewCard(context, data) {
    context.commit('clearDevopsId')
    let errMsg = 'Contact Support: '
    const authService = getInstance()
    const token = await authService.getDOpToken()
    const URL = "https://dev.azure.com/FrogDotNet/Work-Requests/_apis/wit/workitems/$" + data.wIType + "?api-version=6.0"
    // Set body by request type
    const ops = {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json-patch+json',
        Authorization: `Bearer ${token.accessToken}`
      },
      data: JSON.stringify(data.body),
      url: URL
    }
    await axios(ops)
      .then(res => {
        console.log('DevOps createNewCard: ', 'POST request result: ' + res.request.status + ' ' + res.request.statusText)
        context.commit('setDevopsId', res.data.id)
      })
      .catch(function (error) {
        if (error.response) {
          errMsg += error.response.data.message
          console.log('Response Data: ', error.response.data);
          console.log('Response Status: ', error.response.status);
          console.log('Response Headers: ', error.response.headers);
        } else if (error.request) {
          errMsg += error.request
          console.log(error.request);
        } else {
          errMsg += error.message
          console.log('Error', error.message);
        }
        context.dispatch('services/UpdateSnackbar', { text: errMsg, color: 'error' }, { root: true })
        console.log(error.config);
      })
  },
  async getRequestSummaries(context, data) {
    context.commit('clearDevOpsRequests')
    const URL = "https://dev.azure.com/FrogDotNet/_apis/wit/workitemsbatch?api-version=6.0"
    const body = {
      "ids": data.ids,
      "fields": data.fields
    }
    const authService = getInstance()
    const dOpToken = await authService.getDOpToken()
    const ops = {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        Authorization: `Bearer ${dOpToken.accessToken}`
      },
      data: JSON.stringify(body),
      url: URL
    }

    if (data.ids.length > 0) {
      const res = await axios(ops)
        .catch(function (error) {
          if (error.response) {
            context.dispatch('services/UpdateSnackbar', { text: 'Contact Support: ' + error.response.data.message, color: 'error', position: 'bottom', time: 30000 }, { root: true })
            console.log('Response Data: ', error.response.data);
            console.log('Response Status: ', error.response.status);
            console.log('Response Headers: ', error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
          console.log(error.config);
        })
      console.log('DevOps getRequestSummaries: ', 'POST request result: ' + res.request.status + ' ' + res.request.statusText)
      context.commit('setDevOpsRequests', res.data.value)
    } else {
      context.commit('setDevOpsRequests', [])
    }  
  },
  async getRequestSummariesAdmin(context, data) {
    context.commit('clearDevOpsRequests')
    const URL = "https://dev.azure.com/FrogDotNet/_apis/wit/workitemsbatch?api-version=6.0"
    const body = {
      "ids": data.ids,
      "fields": data.fields
    }
    const authService = getInstance()
    const dOpToken = await authService.getDOpToken()
    const ops = {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        Authorization: `Bearer ${dOpToken.accessToken}`
      },
      data: JSON.stringify(body),
      url: URL
    }
    if (data.ids.length > 0) {
      let res = await axios(ops)
        .catch(function (error) {
          if (error.response) {
            context.dispatch('services/UpdateSnackbar', { text: 'Contact Support: ' + error.response.data.message, color: 'error' }, { root: true })
            console.log('Response Data: ', error.response.data);
            console.log('Response Status: ', error.response.status);
            console.log('Response Headers: ', error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
          console.log(error.config);
        })
      // console.log(JSON.stringify(res.data.value, null, 2))
      context.commit('setDevOpsAdminRequests', { name: data.target, data: res.data.value })
    } else {
      console.warn('getRequestSummariesAdmin: No request ids to fetch for ', data.target)
    }
  },
  async updateDevopsCard(context, data) {
    const URL = `https://dev.azure.com/FrogDotNet/_apis/wit/workitems/${data.id}?api-version=6.0`
    const body = data.body
    const authService = getInstance()
    const dOpToken = await authService.getDOpToken()
    const ops = {
      method: 'PATCH',
      headers: {
        "Content-Type": 'application/json-patch+json',
        Authorization: `Bearer ${dOpToken.accessToken}`
      },
      data: JSON.stringify(body),
      url: URL
    }
    if (data.id) {
      let res = await axios(ops)
        // console.log(JSON.stringify(res.data, null, 2))
        // context.commit('setDevOpsAdminRequests', {name: data.target, data: res.data.value})
        .catch(function (error) {
          if (error.response) {
            context.dispatch('services/UpdateSnackbar', { text: 'Contact Support: ' + error.response.data.message, color: 'error' }, { root: true })
            console.log('Response Data: ', error.response.data);
            console.log('Response Status: ', error.response.status);
            console.log('Response Headers: ', error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
          console.log(error.config);
        })
    }
  },
  clearDevOpsAdminRequests(context, data) {
    context.commit('clearDevOpsAdminRequests', data)
  },
  async getDevOpsComments(context, data) {
    context.commit('clearDevOpsComments')
    const URL = `https://dev.azure.com/FrogDotNet/Work%20Request%20Tool/_apis/wit/workitems/${data.id}/comments?order=asc&api-version=6.0-preview`
    const authService = getInstance()
    const dOpToken = await authService.getDOpToken()
    const ops = {
      method: 'GET',
      headers: {
        "Content-Type": 'application/json',
        Authorization: `Bearer ${dOpToken.accessToken}`
      },
      url: URL
    }
    await axios(ops)
      .then(res => {
        this.getDevopsItemData += ': ' + res.status + ' ' + res.statusText
        // console.log(JSON.stringify(res.data, null, 2))
        context.commit('setDevOpsComments', res.data.comments)
      })
      .catch(function (error) {
        if (error.response) {
          context.dispatch('services/UpdateSnackbar', { text: 'Contact Support: ' + error.response.data.message, color: 'error' }, { root: true })
          console.log('Response Data: ', error.response.data);
          console.log('Response Status: ', error.response.status);
          console.log('Response Headers: ', error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
  },
  // COMMENTS POST
  async postDevOpsComment(context, data) {
    context.commit('clearDevOpsComments')
    // Line below could be to clear box if not done in component as part of successful response - not hooked to anything yet
    // context.commit('clearComment')
    const URL = `https://dev.azure.com/FrogDotNet/Work%20Request%20Tool/_apis/wit/workItems/${data.id}/comments?api-version=6.0-preview.3`
    console.log(data)
    const body = {
      "text": data.text,
    }
    const authService = getInstance()
    const dOpToken = await authService.getDOpToken()
    const ops = {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        Authorization: `Bearer ${dOpToken.accessToken}`
      },
      data: JSON.stringify(body),
      url: URL
    }
    await axios(ops)
      .then(res => {
        this.getDevopsItemData += ': ' + res.status + ' ' + res.statusText
        console.log(JSON.stringify(res.data.value, null, 2))
        context.commit('setDevOpsComments', res.data.value)
      })
      .catch(function (error) {
        if (error.response) {
          context.dispatch('services/UpdateSnackbar', { text: 'Contact Support: ' + error.response.data.message, color: 'error' }, { root: true })
          console.log('Response Data: ', error.response.data);
          console.log('Response Status: ', error.response.status);
          console.log('Response Headers: ', error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      })
  },
}
