<template>
	<v-expansion-panels>
		<v-expansion-panel>
			<v-expansion-panel-header class="py-1">
				<v-container class="d-flex justify-start align-center">
					<!-- REPLACE STRING VALUES WITH ACTUAL VALUES FOR STATUS -->
					<v-icon x-small :color="statusColour">mdi-circle</v-icon>
					<h4 class="ml-2">{{ title }}</h4>
				</v-container>
			</v-expansion-panel-header>
			<v-expansion-panel-content>
				{{ summary }}
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
export default {
	props: ['title', 'statusColour', 'summary'],
	data() {
		return {}
	},
	methods: {
		
	}
}
</script>
<style lang="scss" scoped>
// * {
// 	border: 1px solid red;
// }
</style>
