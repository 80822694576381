<template>
	<v-app>
		<navbar />
		<v-main class="mt-4">
			<v-container class="d-flex justify-center">
				<router-view :key="$route.path" />
			</v-container>
			<Snackbar />
		</v-main>
	</v-app>
</template>

<script>
import Snackbar from './components/ui/Snackbar.vue'
// import { PublicClientApplication } from '@azure/msal-browser';

export default {
	name: 'App',
	components: {
		navbar: require('@/components/layout/NavBar.vue').default,
		Snackbar,
	},
	// async created() {
	// 	// create a new msal instance
	//     this.$msalClient = new PublicClientApplication(
	//       this.$store.getters['user/msalConfig']
	//     );
	// 		// create handler for redirects
	//     console.log(this.$msalClient);
	//     try{
	//       this.loading = true
	//       const res = await this.$msalClient.handleRedirectPromise()
	//       if (res) {
	//         this.$msal.handleResponse(res)
	//       }
	//     } catch (error) {
	//       console.error(error)
	//     } finally {
	//       this.loading = false
	//     }
	// }
}
</script>
<style lang="scss">
* {
	font-family: 'Montserrat', sans-serif;
}
.theme--dark.v-card > .v-card__text {
	color: $ashiawhite;
}
h4 {
	margin-bottom: 4px;
}

#app {
	background-image: linear-gradient(to top, $app-bg, #263a9b);
}
.theme--dark.v-text-field--solo > .v-input__control > .v-input__slot {
	background: $component-bg !important;
}

.theme--dark.v-card {
	background: $component-bg !important;
	color: #ffffff;
}

.theme--dark.v-expansion-panels .v-expansion-panel {
	background-color: $component-bg !important;
	color: #ffffff;
}

.theme--dark.v-list {
	background: $component-bg !important;
	color: #ffffff;
}

// .v-snack__wrapper.theme--dark {
// 	background-color: #5054f1 !important;
// 	// color: hsla(0deg, 0%, 100%, 0.87);
// }

.page-container {
	width: 1200px;
}
</style>
